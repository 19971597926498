<template>
    <div class="container content">
        <top-navigation-bar
            title="Hypothesis Testing"
            right-nav="home"
        />
        <div class="route-btn-view d-flex pb-5">
            <router-link
                :to="{name: 'hypothesis/one-mean-input'}"
                tag="button"
                class="action-btn ht-route-btn"
            >
                <b>
                    µ
                </b>
            </router-link>
            <router-link
                :to="{name: 'hypothesis/one-proportion-input'}"
                tag="button"
                class="action-btn ht-route-btn"
            >
                <b>
                    p
                </b>
            </router-link>
            <router-link
                :to="{name: 'hypothesis/two-mean-choice'}"
                tag="button"
                class="action-btn ht-route-btn"
            >
                <b>
                    μ <sub>1</sub> - μ <sub>2</sub>
                </b>
            </router-link>
            <router-link
                :to="{name: 'hypothesis/two-proportion-choice'}"
                tag="button"
                class="action-btn ht-route-btn"
            >
                <b>p <sub>1</sub> - p <sub>2</sub></b>
            </router-link>
            <router-link
                :to="{name: 'hypothesis/one-variance-input'}"
                tag="button"
                class="action-btn ht-route-btn"
            >
                <img
                    class="formulaImage-nav-button"
                    alt="binomial formula"
                    src="@/assets/HTformulaImgs/variance/sigmaSquared.png"
                >
            </router-link>
            <router-link
                :to="{name: 'hypothesis/two-variance-input'}"
                tag="button"
                class="action-btn ht-route-btn"
            >
                <img
                    class="formulaImage-nav-button"
                    alt="binomial formula"
                    src="@/assets/HTformulaImgs/variance/Sigma_Ratios.png"
                >
            </router-link>
        </div>
    </div>
</template>

<script>
// route-button-view
import TopNavigationBar from '@/components/top-navigation-bar.vue'
export default {
    name: 'Hypothesis',
    components: {
        TopNavigationBar
    }
}
</script>
